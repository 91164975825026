<template>
    <modal
    ref="productoDesconocido"
    :titulo="no_aceptar ? 'Producto Desconocido' : ''"
    :img="no_aceptar ? datos.producto.imagen :null"
    :tamano="no_aceptar ? '' : 'modal-xl'"
    :no-aceptar="no_aceptar"
    @guardar="accion_producto">
        <div class="row mx-0">
            <div :class="no_aceptar ? 'col-12' : 'col'">
                <div v-show="!no_aceptar" class="row mx-0 justify-center">
                    <img :src="datos.producto.imagen" class="objet-fit border br-12 p-2 " style="width:111px;height:111px;border: 1px solid #DBDBDB;" />
                </div>
                <div v-show="!no_aceptar" class="row mx-5 my-2 f-600 f-14 justify-center">
                    Producto Desconocido
                </div>
                <div class="row mx-0 my-3">
                    <div class="col-10 text-general f-15">
                        {{ datos.producto.nombre }}
                    </div>
                </div>
            </div>
            <div v-show="no_aceptar === false" class="col border-left">
                <div class="row mx-0 my-2 justify-center">
                    <div class="col-10 text-center">
                        <p class="text-general my-2">Buscar producto de reemplazo</p>
                        <el-select
                        v-model="model.id_producto_nuevo"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="Buscar producto"
                        :remote-method="remoteMethod"
                        class="w-100"
                        :loading="loading"
                        @change="set_minimo"
                        >
                            <el-option
                            v-for="item in opciones"
                            :key="item.id"
                            :label="`${item.sku} - ${item.nombre} - ${item.presentacion}`"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <template v-if="model.id_producto_nuevo != null">
                        <div class="col-8 text-center my-2">
                            <img :src="producto_nuevo.imagen" class="objet-fit border br-12" style="width:111px;height:111px;border: 1px solid #DBDBDB;" />
                        </div>
                        <div class="col-8">
                            <p class="text-general f-600 f-17 mt-1">
                                {{ producto_nuevo.nombre }}
                            </p>
                            <p v-if="producto_nuevo.promocion" class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto_nuevo.data_promocion.promo_valor,idm_moneda) }}</span>
                                <span class="text-general2 f-600 f-13" style="text-decoration:line-through;">  {{ convertMoney(producto_nuevo.precio,idm_moneda) }} </span>
                            </p>
                            <p v-else class="mt-2">
                                <span class="text-general f-600"> {{ convertMoney(producto_nuevo.precio,idm_moneda) }}</span>
                            </p>
                            <span v-if="producto_nuevo.promocion" class="bg-general text-white f-14 br-12 p-1 px-2 my-2">
                                {{ producto_nuevo.data_promocion.texto }}
                            </span>
                            <p class="text-general f-15 mt-1">
                                {{ producto_nuevo.descripcion }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Presentacion:
                                </span>
                                {{ producto_nuevo.presentacion }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Unidad de medida:
                                </span>
                                {{ producto_nuevo.unidad }}
                            </p>
                            <p class="text-general mt-1">
                                <span class="f-15 f-500">
                                    Venta mínima:
                                </span>
                                {{ agregarSeparadoresNumero(producto_nuevo.cantidad_minima,2) }}
                            </p>
                        </div>
                        <div class="col-xl-8 col-lg-9 border br-12 mt-2">
                            <div class="d-flex mx-0">
                                <div class="col px-0">
                                    <p class="text-left text-general my-1 f-15">
                                        cantidad
                                    </p>
                                    <el-input-number
                                    v-model="model.cantidad_nueva" size="small"
                                    class="mb-2 transparent-input"
                                    :min="minimo_nuevo"
                                    :step="salto_nuevo"
                                    :step-strictly="entero_nuevo"
                                    :precision="presicion_nuevo"
                                    />
                                </div>
                                <div class="col text-general text-right d-flex align-items-end justify-content-end pb-3">
                                    <strong>
                                        Total:
                                    </strong>
                                    {{ convertMoney(total_nuevo,idm_moneda) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            model:{
                id_producto_nuevo:null,
                cantidad_nueva:0
            },
            producto_nuevo:{},
            entero_nuevo:false,
            presicion_nuevo:0,
            loading:false,
            tipo:'',
            minimo_nuevo:0,
            salto_nuevo:0,
            opciones:[],
            datos:{producto:{nombre:''}},
            no_aceptar:false
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
        idm_moneda(){
            return this.pedido.idm_moneda
        },
        total_nuevo(){
            if(this.producto_nuevo.promocion){
                return this.producto_nuevo.data_promocion.promo_valor * this.model.cantidad_nueva
            }else{
                return this.producto_nuevo.precio * this.model.cantidad_nueva
            }
        }
    },
    methods:{
        toggle(prod){
            this.model = {
                id_producto_nuevo:null,
                cantidad_nueva:0
            },
            this.no_aceptar = false
            this.opciones = []
            this.minimo_nuevo = 0
            this.salto_nuevo = 0
            this.datos = prod
            this.$refs.productoDesconocido.toggle()
        },
        detalle_desconocido(prod){
            this.datos = prod
            this.no_aceptar = true
            this.$refs.productoDesconocido.toggle()
        },
        async accion_producto(){
            try {

                if(this.model.id_producto_nuevo === null){
                    this.notificacion('Advertencia','Por favor seleccione el producto nuevo','warning')
                    return
                }
                let model = {
                    id_producto:this.model.id_producto_nuevo,
                    cantidad:this.model.cantidad_nueva
                }
                const {data} = await Pedidos.change_producto(this.datos.id, model)
                this.notificacion('Mensaje','Producto remplazado correctamente','success')
                this.$refs.productoDesconocido.toggle()
                //recalculamos todos los datos
                this.$emit('update')



            } catch (e){
                this.error_catch(e)
            }
        },
        async remoteMethod(query){
            try {
                if(query.length > 3){

                    this.loading = true

                    let params = {
                        id_pedido: this.id_pedido,
                        query
                    }

                    const {data} = await Pedidos.buscar_productos(params)
                    this.opciones = data.productos
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        set_minimo(id){
            let obj = this.opciones.find(o=>o.id === id)
            this.producto_nuevo = obj
            this.model.cantidad_nueva = parseFloat(obj.cantidad_minima)
            this.minimo_nuevo = parseFloat(obj.cantidad_minima)
            this.salto_nuevo = parseFloat(obj.cantidad_minima)
            this.entero_nuevo =obj.cantidad_tipo === 1
            this.presicion_nuevo = obj.cantidad_tipo === 1 ? 0 : 2
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
</style>
